<template>
  <div class="main-container">
    <header class="titulo" data-aos="fade-down">
      <div class="logx2">
        <img :src="logo" alt="logo" class="logo" @click="toggleTheme" />
      </div>
      <h1>ENTORNO JURÍDICO VIRTUAL</h1>
      <h2>Acceso a Servicios Profesionales</h2>
    </header>

    <section class="contenedor1" id="servicios" data-aos="fade-up">
      <h4>[ Servicios Jurídicos: Asesoría Legal Personalizada ]</h4>
      <ul class="service-list">
        <li v-for="(service, index) in servicios" :key="index">
          <button class="btn-category" type="button">{{ service }}</button>
        </li>
      </ul>
      <h4>[ Servicios Tecnológicos: Desarrollo de Impacto Digital ]</h4>
      <ul class="service-list2">
        <li v-for="(service2, index) in servicios2" :key="index">
          <button class="btn-category" type="button">{{ service2 }}</button>
        </li>
      </ul>
      <div class="footer-text" data-aos="fade-in">
      <div class="button2">
        <button @click="openForm" class="btn-consulta" aria-label="Abrir formulario de consulta en una nueva pestaña">
          FORMULARIO DE CONSULTA
        </button>
      </div>
      <p>Tu consulta es el primer paso, estoy lista para acompañarte.</p>
      <p>JULIETA CALDERON - ABOGADA | DESARROLLADORA FS</p>
      <p>ar.jcvlenv / / #PUEBLOXXV / / #ProfesionalDelPueblo </p>
    </div>
    </section>

    <div ref="carousel" class="carousel-container" data-aos="fade-in">
      <div class="carousel">
        <div
          v-for="(project, index) in extendedProjects"
          :key="'up-' + index"
          class="carousel-item"
        >
          <router-link v-if="!isExternal(project.link)" :to="project.link" exact>
            <img :src="project.image" :alt="project.alt" class="carousel-image" />
          </router-link>

          <a v-else :href="project.link" target="_blank" rel="noopener noreferrer">
            <img :src="project.image" :alt="project.alt" class="carousel-image" />
          </a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "InicioCont",
  props: {
    theme: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      servicios: [
        "CONSTITUCIONAL",
        "ADMINISTRATIVO",
        "TRABAJO",
        "CONSUMO",
        "AMBIENTE",
        "INCIDENCIA COLECTIVA",
        "DISCRIMINACIÓN",
        "TECNOLOGÍA",
      ],
      servicios2: ["HTML", "CSS", "JAVASCRIPT", "PYTHON", "SQL", "MACHINE LEARNING", "UX/UI", "GNU-LINUX"],
      projects: [
        { image: require('@/assets/AR/stories.png'), link: '/libro', alt: 'julietxxv' },
        { image: require('@/assets/AR/img14.png'), link: '/genia', alt: 'genia' },
        { image: require('@/assets/AR/img21.png'), link: '/proyectos', alt: 'genia' },
        { image: require('@/assets/AR/img11.png'), link: '/', alt: 'dispojcv25' },
      ]
    };
  },
  computed: {
    logo() {
      return this.theme === 'dark' 
        ? require('@/assets/AR/logo-oscuro.png') 
        : require('@/assets/AR/logo-claro.png');
    },
    extendedProjects() {
      return [
        ...this.projects,
        ...this.projects,
        ...this.projects,
        ...this.projects,
        ...this.projects,
        ...this.projects,
        ...this.projects,
        ...this.projects,
        ...this.projects,
        ...this.projects,
        ...this.projects,
        ...this.projects,
      ];
    }
  },
  mounted() {
    AOS.init();
    this.$nextTick(() => {
      this.startCarousel();
      AOS.refresh();
    });
  },
  methods: {
    openForm() {
      window.open("https://forms.gle/8sAhUefgFMZ87mNf9", "_blank");
    },
    isExternal(link) {
      return link.startsWith("http");
    },
    startCarousel() {
      const carousel = this.$refs.carousel;
      const scrollSpeed = 1;

      const scrollLoop = (carousel, speed) => {
        carousel.scrollLeft += speed;

        const maxScroll = carousel.scrollWidth / 2;

        if (speed > 0 && carousel.scrollLeft >= maxScroll) {
          carousel.scrollLeft -= maxScroll;
        } else if (speed < 0 && carousel.scrollLeft <= 0) {
          carousel.scrollLeft += maxScroll;
        }

        requestAnimationFrame(() => scrollLoop(carousel, speed));
      };

      scrollLoop(carousel, scrollSpeed);
    },
    toggleTheme() {
      const newTheme = this.theme === 'dark' ? 'light' : 'dark';
      this.$emit("theme-changed", newTheme);
    }
  }
};
</script>

<style scoped>
[data-aos="fade-down"],
[data-aos="fade-up"],
[data-aos="zoom-in"],
[data-aos="fade-right"] {
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

[data-aos].aos-animate {
  opacity: 1;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px;
  margin-top: 3em;
  gap: 60px;
}

.titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
}

.titulo .logx2 {
  width: 150px;
  height: 150px;
  position: relative;
}

.titulo .logx2 img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  transition: opacity 0.3s ease;
  position: absolute;
}

.titulo h1 {
  font-size: 1.5em;
  margin: 0;
  color: var(--color-texto);
}

.titulo h2 {
  font-size: 1.2em;
  font-family: "Lora";
  font-weight: bold;
  margin: 0;
  color: var(--color-texto);
}

.titulo .logx2 {
  width: 150px;
  height: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotate 30s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.titulo .logx2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  transition: opacity 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
}

.contenedor1 {
  width: 100%;
  max-width: 1800px;
  text-align: center;
}

.service-list,
.service-list2 {
  list-style: none;
  padding: 0;
  margin-bottom: 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.btn-category {
  padding: 10px 20px;
  margin: 5px;
  font-family: "Chakra Petch";
  background-color: var(--color-primario);
  color: var(--color-fondo);
  border: none;
  border-radius: 5px;
  font-size: 0.8em;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-category:hover {
  background-color: var(--color-secundario);
  color: var(--color-fondo);
}

.button2 button {
  background-color: var(--color-secundario);
  font-family: "Chakra Petch", sans-serif;
  color: var(--color-fondo);
  font-size: 1em;
  border: none;
  border-radius: 5px;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.button2 button:hover {
  background-color: var(--color-primario);
  transform: scaleX(1.02);
}

.carousel-container {
  width: 100%;
  max-width: 1200px;
  overflow: hidden;
  position: relative;
  margin-top: 3em;
}

.carousel {
  display: flex;
  gap: 20px;
  scroll-snap-type: x mandatory;
}

.carousel-item {
  flex: 0 0 auto;
  min-width: 200px;
  max-width: 200px;
  scroll-snap-align: start;
}

.carousel-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out, filter 0.3s ease;
}

.carousel-item:hover .carousel-image {
  transform: scale(1.1);
  filter: brightness(1.1);
}

.footer-text {
  margin-top: 0.1em;
  text-align: center;
  font-family: "Abeezee";
  
  color: var(--color-texto);
  width: 100%;
}

.footer-text p {
  font-size: 1em;
}

.footer-text p:first-child {
  font-weight: bold;
  font-style: italic;
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .carousel-item {
    min-width: 150px;
    max-width: 150px;
  }

  .titulo h1 {
    font-size: 1.3em;
  }

  .titulo h2 {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .button2 button {
    font-size: 0.8em;
    padding: 10px;
  }
}
</style>