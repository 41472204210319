<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="logo-container">
        <div class="logo rotating">
          <a target="_blank" rel="noopener noreferrer">
            <img :src="logo" alt="Logo" @click="toggleTheme" />
          </a>
        </div>
        <div class="logo">
          <a href="https://aramverse.vercel.app" target="_blank" rel="noopener noreferrer">
            <img :src="logo3" alt="Logo 2" />
          </a>
        </div>
        <div class="logo">
          <a href="https://argentinaigualitaria.vercel.app" target="_blank" rel="noopener noreferrer">
            <img :src="logo1" alt="Logo 3" />
          </a>
        </div>
        <div class="logo">
          <a href="https://profesionalesdelpueblo.netlify.app" target="_blank" rel="noopener noreferrer">
            <img :src="logo2" alt="Logo 4" />
          </a>
        </div>
        <div class="logo">
          <a href="https://amazonasargentina.netlify.app" target="_blank" rel="noopener noreferrer">
            <img :src="logo4" alt="Logo 5" />
          </a>
        </div>
        <div class="logo">
          <a href="https://empatics.vercel.app" target="_blank" rel="noopener noreferrer">
            <img :src="logo5" alt="Logo 6" />
          </a>
        </div>
      </div>
      <p>2025 JCVLENV / / ARD</p>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      required: true
    }
  },
  computed: {
    logo() {
      return this.theme === 'light' 
        ? require('@/assets/AR/logo-oscuro2.png') 
        : require('@/assets/AR/logo-oscuro2.png');
    },
    logo1() {
      return require('@/assets/AR/img7.png');
    },
    logo2() {
      return require('@/assets/AR/img12.png');
    },
    logo3() {
      return require('@/assets/AR/img19.png');
    },
    logo4() {
      return require('@/assets/AR/img20.png');
    },
    logo5() {
      return require('@/assets/AR/img13.png');
    }
  },
  methods: {
    toggleTheme() {
      const newTheme = this.theme === 'dark' ? 'light' : 'dark';
      this.$emit("theme-changed", newTheme);
    }
  }
};
</script>

<style scoped>
a{
font-style: none;
}
.footer {
  padding: 1em 1em;
  background-color: #020202;
  color: #fef9e6;
  text-align: center;
  transition: background-color 0.3s ease;
}
.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  margin-bottom: 0.5em;
}
.footer-content p {
  font-size: 1em;
  font-family: "Chakra Petch";
  opacity: 75%;
}
.logo-container {
  display: flex;
  gap: 15px;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1em;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo img {
  max-width: 100%;
  max-height: 100%;
  transition: transform 0.3s ease, filter 0.3s ease;
  cursor: pointer;
}
.logo.rotating img {
  max-width: 100%;
  max-height: 100%;
  animation: rotate 30s linear infinite;
  cursor: pointer;
}
.logo img:hover {
  transform: scale(1.1);
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 768px) {
  .footer {
    padding: 1em;
  }
  .logo {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 480px) {
  .logo {
    width: 30px;
    height: 30px;
  }
}
</style>
